<template>
  <div class="w-100 pt-4">
    <AsyncList
      without-loader
      class="h-100"
    >
      <b-container
        fluid="xl"
      >
        <div class="d-flex align-items-center mb-4 mt-4">
          <div class="h3">
            Очередь импорта
          </div>
          <base-date-picker
            v-model="date"
            class="ml-4"
            :max-date="maxDate"
          />
          <b-button
            class="ml-2"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="filterList"
          >
            <b-icon icon="search" />
          </b-button>

          <!-- <b-button
            variant="primary"
            class="ml-auto"
            size="sm"
            @click="() => openRoleEditModal()"
          >
            Добавить новую роль
          </b-button> -->
        </div>

        <div
          v-if="!totalCount && !isLoading"
          class="px-3"
        >
          Ничего не найдено
        </div>

        <b-table
          v-if="totalCount"
          class="bg-white m-0"
          bordered
          :fields="fields"
          :items="items"
        >
          <template #cell(createdAt)="row">
            {{ formatDate(row.item.createdAt) }}
          </template>

          <template #cell(companyName)="row">
            <div :class="getImportClass(row.item)">
              {{ types[row.item.type] + ' - ' + row.item.companyName + ' / ' + row.item.programName }} <br>
              {{ row.item.originalName }}
            </div>
          </template>

          <template #cell(downloadsCount)="row">
            {{ row.item.successCount ? row.item.successCount : '0' }} / {{ row.item.allRowsCount }}
          </template>

          <template #cell(cloudKey)="row">
            <b-button
              size="sm"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.cloudKey, row.item.originalName)"
            >
              Скачать
            </b-button>
          </template>
          <template #cell(fileErrorCloudKey)="row">
            <b-button
              v-if="row.item.fileErrorCloudKey"
              size="sm"
              variant="warning"
              :disabled="isFileLoading"
              :type="$const.PRIMARY_BUTTON"
              @click="saveImportFile(row.item.fileErrorCloudKey, row.item.originalFileErrorName)"
            >
              Скачать
            </b-button>

            <template v-else>
              —
            </template>
          </template>
          <template #cell(fileInfoCloudKey)="row">
            <b-button
              v-if="row.item.fileInfoCloudKey"
              size="sm"
              variant="outline-primary"
              :disabled="isFileLoading"
              :type="$const.PRIMARY_BUTTON"
              @click="saveImportFile(row.item.fileInfoCloudKey, row.item.originalFileInfoName)"
            >
              Скачать
            </b-button>

            <template v-else>
              —
            </template>
          </template>
        </b-table>
        <b-skeleton-table
          v-if="isLoading"
          :rows="25"
          :columns="fields.length"
          :table-props="{ bordered: true, striped: true }"
        />
      </b-container>
      <div
        v-if="totalCount"
        class="container-xl flex align-items-center my-4"
      >
        <b-dropdown
          id="dropdown-1"
          :text="`${take}`"
          split
          class="mr-2 bg-white"
          size="sm"
          split-variant="outline-primary"
          variant="primary"
        >
          <b-dropdown-item @click="tableSizeHandler(25)">
            25
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="tableSizeHandler(50)">
            50
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="tableSizeHandler(100)">
            100
          </b-dropdown-item>
        </b-dropdown>

        <b-pagination
          v-model="page"
          :total-rows="allCount"
          :per-page="take"
          class="m-0"
          @input="() => itemsFetch()"
        />
      </div>
    </AsyncList>
  </div>
</template>

<script>
import { format, formatISO, parseISO } from '@evd3v/date-fns';

import AsyncList from '@/components/AsyncList';
import { dateWithoutTime, dateWithoutTimeZone } from '@/helpers/utils';
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'InsuranceImportList',
  page: {
    title: 'CRM Doctis - Очередь импорта',
  },
  components: {
    AsyncList,
    BaseDatePicker,
  },
  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      fields: [
        {
          key: 'createdAt',
          label: 'Дата',

        },
        {
          key: 'companyName',
          label: 'Информация',
        },
        {
          key: 'downloadsCount',
          label: 'Количество',
        },
        {
          key: 'cloudKey',
          label: 'Файл',
          class: 'td-button',
        },
        {
          key: 'fileErrorCloudKey',
          label: 'Ошибка',
          class: 'td-button',
        },
        {
          key: 'fileInfoCloudKey',
          label: 'Номера корпоративных полисов',
          class: 'td-button',
        },
      ],
      items: [],
      allCount: 0,
      take: 25,
      page: 1,
      date: null,
      maxDate: new Date(),
      types: {
        0: 'Добавление новых',
        1: 'С заменой существующих',
        2: 'Открепление клиник от полисов',
        3: 'Открепление',
        4: 'Изменение объема услуг',
        5: 'Импортировать новые полисы + СМС',
        6: 'Удаление полисов',
      },
    };
  },
  computed: {
    totalCount() {
      return this.items.length;
    },
  },
  async mounted() {
    await this.itemsFetch();
  },
  methods: {
    async filterList() {
      this.page = 1;
      await this.itemsFetch();
    },
    tableSizeHandler(value) {
      this.take = value;
      this.page = 1;
      this.itemsFetch();
    },
    async loadMoreItems() {
      if (this.isLoading) return;

      this.page += 1;
      await this.itemsFetch();
    },
    async itemsFetch() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        const { originalCount, paginatedCollection } = (await this.$store.dispatch(this.$types.IMPORT_LIST_FETCH, {
          skip: (this.page - 1) * this.take,
          take: this.take,
          date: this.date ? dateWithoutTime(formatISO(this.date)) : null,
        }));

        this.items = paginatedCollection.map((item) => ({ ...item, createdAt: `${item.createdAt}z` }));
        this.allCount = originalCount;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      const newDate = dateWithoutTimeZone(date);
      return format(parseISO(new Date(newDate).toISOString()), 'dd.MM.yyyy HH:mm');
    },
    async saveImportFile(id, name) {
      this.isFileLoading = true;
      await this.$store.dispatch(this.$types.IMPORT_FILE_FETCH, { id, name });
      this.isFileLoading = false;
    },
    getImportClass({ isError, isProcessed }) {
      if (isProcessed && !isError) return 'text-success';
      if (!isProcessed && !isError) return 'text-warning';
      if (isError) return 'text-danger';

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.bg-white .td-button {
    width: 100px;
    text-align: center;
    vertical-align: inherit;
  }

  ::v-deep.bg-white thead th {
    border-bottom-width: 0px;
    vertical-align: inherit;
  }
</style>
